<template>
<div>
  <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.subtitles')">
    <b-row>
    <b-col cols="6" class="mb-2" v-for="sub in subtitles" :key="sub.id">
      {{ sub.language }} 
      <b-button :data-subtitleid="sub.id" @click="removeSubtitle"  class="ml-2 removeSubtitle" :title="$t('videos.remove-subtitle')">
        <BIconXCircle font-scale="1.1" variant="danger"  aria-hidden="true" />
      </b-button>
    </b-col>
    </b-row>
    <b-button v-b-modal.subtitleModal variant="outline-primary" class="mt-3"> {{ $t('videos.add-subtitles') }}...</b-button>
  </b-form-group>
  <b-modal size="lg" id="subtitleModal" :title="$t('videos.add-subtitle')" hide-footer>
    <div class="m-3">
      <b-form-group label-cols-md="12" label-cols-lg="4" :label="$t('videos.select-subtitle-language')+':'">
        <b-form-select v-model="selectedSubtitleLanguage">
          <b-form-select-option :value="null">Please select an option</b-form-select-option>
          <b-form-select-option v-for="l in avaiableLanguageList" :key="l" :value="l">{{ l }}</b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group label-cols-md="12" label-cols-lg="4" :label="$t('videos.select-subtitle-file') + ':'">
        <BrowseButton :name="name" :buttonText="$t('videos.chooseSubtitle')" :noFile="$t('videos.no-subtitle-file-selected')" />
        <small>{{ $t('videos.recommended-subtitle-formats')}}: srt, stl, smi, xml, vtt</small>
      </b-form-group>

      <b-form-group label-cols-sm="12" label-cols-md="4">
        <b-overlay :show="busy" spinner-small spinner-variant="primary" opacity="0.5" class="d-inline-block">
          <b-button variant="primary" @click="saveSubtitle" :disabled="busy">
            {{ $t('videos.add-subtitle') }}
          </b-button>
        </b-overlay>
      </b-form-group>
    </div>
  </b-modal>
</div>
</template>

<script>
import BrowseButton from '@/components/util/BrowseButton.vue'
import { BFormSelect, BFormSelectOption, BIconXCircle} from 'bootstrap-vue'
export default {
  name: "Subtitles",
  components: { BrowseButton, BFormSelect, BFormSelectOption, BIconXCircle},
  data() {
    return {
      busy: Boolean,
      selectedSubtitleLanguage: null,
      name: "subtitleFile"
    }
  },
  props: {
    avaiableLanguageList: Array,
    media: Object
  },
  created() {
    this.busy = false;
  },
  computed: {
    subtitles: {
      get() {
        return this.media.subtitles;
      },
      set(value) {
        this.media.subtitles = value;
      }
    }
  },
  watch: {
    subtitles() {
      this.subtitles = this.media.subtitles;
    }
  },
  methods: {
    saveSubtitle() {
      this.busy = true;
      // Form validation
      const data = new FormData();
      data.append("subtitleLanguage", this.selectedSubtitleLanguage);
      data.append("subtitleFile", document.getElementById("subtitleFile").files[0]);
      data.append("mediaId", this.media.id);
      const axiosConfig = {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: this.getAuthData().accessToken
            }
      }
      this.$http.post("cms/api/media/save-subtitle", data, axiosConfig).then(r => {
            if (r.data) {
                //console.log(r.data);
                this.subtitles = r.data.subtitles;
                this.$bvModal.hide("subtitleModal");
              }
            }).catch(e => {
              console.log("Greška!");
              console.log(e);
            })
      this.busy = false;
    },

    removeSubtitle(e) {
      // console.log("Media je:" + this.media.id);
      // console.log(e.currentTarget.getAttribute("data-subtitleid"))
      const data = new FormData();
      data.append("mediaId", this.media.id);
      data.append("subtitleId", e.currentTarget.getAttribute("data-subtitleid"));
      const axiosConfig = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.getAuthData().accessToken
          }
      }

      this.$http.post("cms/api/media/remove-subtitle", data, axiosConfig).then(r => {
          if (r.data) {
              // console.log(r.data.subtitles);
              this.subtitles = r.data.subtitles;
            }
          }).catch(e => {
            console.log("Greška!");
            console.log(e);
          })  
    }
  }
}
</script>

<style>
  .removeSubtitle {background-color: transparent; padding: 0 .3rem; border: none}
  .removeSubtitle:hover, .removeSubtitle:active, .removeSubtitle:focus {background-color: transparent !important; box-shadow: none !important;}
</style>
